

html, main {
    background-color: #f6f5ee;

}
.menuBoxNav {
    font-family: Verdana, Geneva, sans-serif;
    font-size: 8pt;
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
}
.menuBoxNav li{
    list-style-type: none;
    border-bottom: 1px dotted black;
}
.menuBoxNav li a{
    text-decoration: none;
    color: black;
}
.menuBoxNav li a:hover{
    text-decoration: none;
    font-weight: bold; 
}
.page li{
margin-left: 15px;  
}
h1{
    font-size: 16pt;
}
.footerKontakt {
    font-size: 8pt;
}
#corner-img {
    margin: -8px;
}
ul{
    
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
}
 body {
    width:100%;
    height:100;
    font-family: Trebuchet, "Trebuchet MS", "Lucida Grande", Tahoma, Helvetica, Arial, Verdana, sans-serif;
    font-size: 14px;
}


#arrow-right {
    margin-top: 135px;
    margin-left:-70px;
    
    float:left;
    width: 100px;
    height: 80px;
    z-index:10;
    background-color: #f6f5ee;

    clip-path: polygon(70% 20%, 100% 47%, 100% 53%, 70% 80%, 0 80%, 0 20%);
}

.completePage{
    background-color: white;
    box-shadow: 1px 2px 10px 0px rgb(218, 214, 214);
    position: relative;
    width: 950px;
    height: 700px;
    margin: 0px auto 0px;
    z-index: 2;
}
.page {
	top: 0px;
	right: 0px;
	margin: 0px auto 0px;
	padding-top:10px;
	padding-left:15px;
	padding-right:15px;
	padding-bottom:15px;
	height: auto;
	width: 420px;
	overflow: hidden;
	position: absolute;
	visibility: visible;
    background-color: rgba(255, 255, 255, 0.8);
	z-index:1000;
}
.page p, li{
    color: rgb(77, 74, 74);
}
.page img{
    width: 368px;
    height: 242px;
}
#topleftfade {
    float: left;
    height: 220px;
    width: 25px;
    z-index: -1;
}
.imgDiv{
    position: relative;
    float: left;

    height: 480px;
    width: 950px;
    z-index: 2;
    overflow: hidden;
}


header{
    position: relative;
    float: left;
    height: 220px;
    width: 100%;
    z-index: 2;
    overflow: visible;
    
}

.imageBG{
    position: relative;
    float: left;
    height: auto;
    width: auto;
    z-index: 0;
}

.imageTest{
    position: relative;
    float: left;
    height: auto;
    width: auto;
    z-index: 0;
}
#logo-img{
    float:left;
    padding: 10% 20px 0px 5%;    
    bottom: 0px;
    
}
.menuBox{
    float:left;
    width: 200px;
}

#registerList{
    list-style-type: none;
}
#kontaktSmallText{
    font-size: smaller;
}

td.invalid-form{
    color: red;
    width: 300px !important;
}
